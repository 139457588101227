import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _debounce from "lodash/debounce";
import Logger from "utils/logger";
import { sceneEditorMapStateToProps } from "mapToProps/sceneEditor";
import * as sceneActions from "actions/sceneEditor";
import LayoutSelector from "modules/StoryEditor/LayoutSelector";
import AssetSelector from "modules/Library/AssetSelector";
import SlideShowEditor from "./SlideshowEditor";
import GalleryEditor from "./GalleryEditor";
import SceneElementEditor from "../SceneElementEditor";
import GradedQuestionEditor from "./GradedQuestionEditor";
import GradedResultEditor from "./GradedResultEditor";
import TimelineEventEditor from "./TimelineEventEditor";
import BasemapEditor from "./BasemapEditor";
import MapPlaceEditor from "./MapPlaceEditor";
import CollectionItemEditor from "./CollectionItemEditor";
import ImageCropper from "modules/Library/ImageCropper";

import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _capitalize from "lodash/capitalize";

import { AssetContainer } from "@terraincognita/ui-core"; // Loader

import styles from "./styles.scss";
import "./styles.scss";

// Editors for Scene Types
/**
 * SceneEditor
 * he will have a chooser , that leverages the scene type to know what editor comoponet how will display
 */

/**
 * The Chooser is a factory that mounts the appropriate scene editor
 * "subclass" based on the scene type being edited. This component
 * must extend React.Component so we can define the wrappedComponentRef
 * property on certain editors that need to be notified when an asset
 * is selected in the AssetChooser component mounted in this component.
 */
class Chooser extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { type } = this.props;
    if (type) type = type.toLowerCase();
    let editor = null;
    switch (type) {
      case "slideshow-slide":
      case "slideshowimage":
      case "SlideshowImage":
        editor = <SlideShowEditor {...this.props} />;
        break;

      case "quiz-graded-questions":
        editor = (
          <GradedQuestionEditor
            {...this.props}
            wrappedComponentRef={c => (this.editor = c)}
          />
        );
        break;

      case "quiz-graded-results":
        editor = (
          <GradedResultEditor
            {...this.props}
            wrappedComponentRef={c => (this.editor = c)}
          />
        );
        break;

      case "timeline-events":
        editor = (
          <TimelineEventEditor
            {...this.props}
            wrappedComponentRef={c => (this.editor = c)}
          />
        );
        break;

      case "map-places":
        editor = (
          <MapPlaceEditor
            {...this.props}
            wrappedComponentRef={c => (this.editor = c)}
          />
        );
        break;

      case "map-basemap":
        editor = <BasemapEditor {...this.props} />;
        break;

      case "gallery": // this will be an instance of the sceneBrower but will be passed subScenes
      case "browser":
      case "custom":
      case "cargo-game":
      case "colonykit-game":
        editor = <GalleryEditor {...this.props} />;
        break;

      case "cover":
        editor = <SlideShowEditor {...this.props} />;
        break;

      case "collection":
        editor = <CollectionItemEditor {...this.props} />;
        break;

      default:
        editor = <div>Scene Editor Loading...</div>;
    }

    return editor;
  }
}

class SceneEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeElement: this.props.activeElement || null,
      editingForm: this.props.editingForm,
      displayModalChanges: false,
      layoutSelectorEditMode: false,
      assetSelectorOpen: false,
      assetSelectorAllowsMultiple: true,
      isSceneElementEditorOpen: this.props.isSceneElementEditorOpen
        ? this.props.isSceneElementEditorOpen
        : false,
      imageCropperImageProps: {
        param: null,
        cropCoordinates: null,
        width: null,
        height: null
      },
      imageCropperOpen: false,
      sceneEditorTop: null,
      sceneEditorLeft: null
    };

    if (this.props.activeEditingSubScene) {
      this.props.createSceneRevertCopy(this.props.activeEditingSubScene);
    } else {
      this.props.createSceneRevertCopy(this.props.activeEditingScene);
    }

    this.openLayoutSelector = this.openLayoutSelector.bind(this);
    this.closeLayoutSelector = this.closeLayoutSelector.bind(this);
    this.openAssetSelector = this.openAssetSelector.bind(this);
    this.closeAssetSelector = this.closeAssetSelector.bind(this);
    this.handleAssetSelected = this.handleAssetSelected.bind(this);
    this.closeSceneElementEditor = this.closeSceneElementEditor.bind(this);
    this.handleSceneElementEditorSave =
      this.handleSceneElementEditorSave.bind(this);
    this.processAssetDelete = this.processAssetDelete.bind(this);
    this.closeImageCropper = this.closeImageCropper.bind(this);
    this.openImageCropper = this.openImageCropper.bind(this);
    this.processCropAction = this.processCropAction.bind(this);
    this.navigateToFeedback = this.navigateToFeedback.bind(this);
    this.setActiveElement = this.setActiveElement.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  getPercentageCropData(cropData, assetData) {
    const x = (cropData.x * 100) / assetData.width;
    const y = (cropData.y * 100) / assetData.height;
    const newWidth = (cropData.width * 100) / assetData.width;
    const newHeight = (cropData.height * 100) / assetData.height;
    return { x, y, width: newWidth, height: newHeight };
  }

  handleLanguageChange(value) {
    this.props.changeLanguage(value);
  }

  // we will need to have a chooser based on scene type
  render() {
    const {
      accountId,
      activeEditingScene,
      activeEditingSubScene,
      activeEditingElement,
      goBackAction,
      needsSaving,
      storyData,
      scenes,
      // styles,
      storyID,
      sceneID,
      elementID,
      slugBase,
      sceneType,
      subsceneType,
      // functions
      updateScene,
      updateSubscene,
      isSceneElementEditorOpen,
      setActiveEditingSubscene,
      activeSlideAssetCloudId,
      exitEditingSubscene,
      activeSceneFormData,
      galleryAssetScene,
      galleryAssetSceneType,
      galleryAssetSceneFormData,
      galleryAssetScenePercCropData,
      galleryAssetCloudId
    } = this.props;

    /*
    Reusing the variable percCropData, which is crop coordinates for 
    an image being edited in the Scene Element Editor. It could point
    to a subscene gallery asset, a gallery/collection asset or a 
    slideshow asset.
    */
    const percCropData = galleryAssetScenePercCropData
      ? this.getPercentageCropData(
          galleryAssetScenePercCropData,
          galleryAssetSceneFormData
        )
      : this.props.percCropData
      ? this.getPercentageCropData(
          this.props.percCropData,
          this.props.activeEditingFeedback &&
            this.props.activeEditingFeedback.elements
            ? this.props.activeEditingFeedback.elements.asset
            : activeSceneFormData
        )
      : this.state.imageCropperImageProps.cropCoordinates
      ? this.getPercentageCropData(
          this.state.imageCropperImageProps.cropCoordinates,
          {
            width: this.state.imageCropperImageProps.width,
            height: this.state.imageCropperImageProps.height
          }
        )
      : null;

    const imageCropperImageSrc = this.state.imageCropperImageProps.cloudPublicId
      ? `${location.protocol}//res.cloudinary.com/dgpzfuyzy/image/upload/c_crop,f_auto,q_auto/v1/${this.state.imageCropperImageProps.cloudPublicId}`
      : `${galleryAssetCloudId || activeSlideAssetCloudId}.jpg`;

    const inputForm = {
      title: {
        type: "text",
        isRequired: true,
        label: "Title",
        param: "title",
        value: "",
        inlineLabel: true,
        order: 1
      }
    };

    /*
    There seems to be a timing issue on deep links where the store doesn't 
    have all the data on scenes when mapStateToProps is executed so we get
    no active scene/sub-scene data. In that case, just output an empty <div>.
    */
    if (_isEmpty(activeEditingScene) && _isEmpty(activeEditingSubScene)) {
      return <div className="scene-editor">Loading...</div>;
    }

    const TextStyleCard =
      backgroundElement =>
      ({ item }) => {
        const { value } = item;

        const backgroundOverrideClasses = `${
          backgroundElement.color ? "custom-bg-color" : ""
        } ${backgroundElement.assetID ? "custom-bg-asset" : ""}`;

        const backgroundClasses =
          this.props.dataReadyForSave.background.classes;
        let backgroundContainerClasses = "";

        if (backgroundClasses) {
          const backgroundAnimationClassName =
            backgroundClasses.backgroundAnimation
              ? `animate ${backgroundClasses.backgroundAnimation} ${backgroundClasses.backgroundAnimationSpeed}`
              : "";
          backgroundContainerClasses = `${backgroundClasses.backgroundFilter} ${backgroundClasses.backgroundEffect} ${backgroundAnimationClassName}`;
        }

        return (
          <div
            className={`thumbnail theme-bg-${
              backgroundClasses && backgroundClasses.themeBackground
            } ${backgroundOverrideClasses}`}
          >
            <div className="thumbnail-content">
              <div
                className={`sc-module text block-${
                  value < 10 ? `0${value}` : value
                }`}
              >
                <div className="text-content">
                  <div className="inner-wrapper">
                    <p className="title small">Lorem Ipsum Dolor</p>
                    <p className="body large">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit.
                      Quisque ac metus a est eleifend condimentum. In arcu
                      justo, pretium quis ornare non, rutrum et eros. Lorem
                      ipsum dolor sit amet consectetur adipiscing elit. Quisque
                      ac metus a est eleifend condimentum. In arcu justo,
                      pretium quis ornare non, rutrum et eros.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnail-background">
              <div className="content-container-wrapper">
                <div className="content-container"></div>
              </div>
              <div
                className={`background-container ${backgroundContainerClasses}`}
              >
                <div
                  className="background-color"
                  style={
                    backgroundElement.color
                      ? { backgroundColor: backgroundElement.color }
                      : {}
                  }
                ></div>
                <AssetContainer
                  className="background-image asset"
                  {...backgroundElement}
                />
                <div className="background-overlay-scrim"></div>
              </div>
            </div>
          </div>
        );
      };

    const AssetFrameCard = ({ item }) => {
      const { value } = item;

      const { background } = this.props.dataReadyForSave;

      const backgroundOverrideClasses = `${
        background.color ? "custom-bg-color" : ""
      } ${background.assetID ? "custom-bg-asset" : ""}`;

      const backgroundClasses = background.classes;
      let backgroundContainerClasses = "";

      if (backgroundClasses) {
        const backgroundAnimationClassName =
          backgroundClasses.backgroundAnimation
            ? `animate ${backgroundClasses.backgroundAnimation} ${backgroundClasses.backgroundAnimationSpeed}`
            : "";
        backgroundContainerClasses = `${backgroundClasses.backgroundFilter} ${backgroundClasses.backgroundEffect} ${backgroundAnimationClassName}`;
      }

      return (
        <div
          className={`thumbnail theme-bg-${
            backgroundClasses && backgroundClasses.themeBackground
          } ${backgroundOverrideClasses} ${value}`}
        >
          <div className="thumbnail-content">
            <div className={`sc-module asset ${value}`}>
              <figure />
            </div>
          </div>
          <div className="thumbnail-background">
            <div className="content-container-wrapper">
              <div className="content-container"></div>
            </div>
            <div
              className={`background-container ${backgroundContainerClasses}`}
            >
              <div className="background-color"></div>
              <div className="asset">
                {/* <figure className="background-image asset image-container">
                  TBD
                </figure> */}
              </div>
              <div className="background-overlay-scrim"></div>
            </div>
          </div>
        </div>
      );
    };

    const ShadowCard = ({ item }) => {
      const { value } = item;

      return (
        <div className={`thumbnail ${value}`}>
          <div className="thumbnail-content"></div>
          <div className="thumbnail-background"></div>
        </div>
      );
    };

    // Manually assign components to the form.
    const editingForm = { ...this.state.editingForm };
    const quillRTEExtClassAccounts = [
      "60af0dac7cab44e1307d4068",
      "59a62db74b16b4ca57608c36",
      "5e2ce355a32613febc9912f2",
      "5f9c19dc310fff5346a5bfd3",
      "608f2a2be696ddf2ebdd8bf0",
      "60af0ca97cab44e1307d4067",
      "61ec6037704390d02b07f5f3",
      "622a3a649dbe29d6b4fcd89a",
      "5a67cd4f3cb5cc0eef9002bd",
      "6241e1e443d5fdb9251207d6",
      "596d16289e6eb00b3af44e51", // Test Account
      "5ae0fe3b9c6767a68d8a956d", // Sixth Floor
      "6564f58f3954052227a7d28d", // Old Red
      "67a112175cfe959c4fc0fee7" // Institute of Texan Cultures
    ];

    if (activeSceneFormData) {
      if (activeSceneFormData.formType === "text") {
        editingForm.style.component = TextStyleCard(
          activeEditingScene.elements.background
        );
        editingForm.shadow.component = ShadowCard;
        if (!quillRTEExtClassAccounts.includes(accountId)) {
          editingForm.text.type = "textarea";
        }
      } else if (
        activeSceneFormData.formType === "image" ||
        activeSceneFormData.formType === "pdf" ||
        activeSceneFormData.formType === "video"
      ) {
        editingForm.frame.component = AssetFrameCard;
        editingForm.shadow.component = ShadowCard;
      }
    }

    return (
      <div className="scene-editor">
        <SceneElementEditor
          open={this.state.isSceneElementEditorOpen}
          closeAction={this.closeSceneElementEditor}
          form={this.state.editingForm}
          languages={this.props.languages}
          langID={this.props.langID}
          handleLanguageChange={this.handleLanguageChange}
          handleInputChange={this.props.handleInputChange}
          leftPosition={this.state.sceneEditorLeft}
          topPosition={this.state.sceneEditorTop}
          formData={galleryAssetSceneFormData || activeSceneFormData}
          /*
          Inspect gallery asset scene first, then subscene and then
          scene. We have to inspect the activeEditingScene's isSaved prop
          here in case a slideshow scene element is being edited. 
          */
          isDirty={
            galleryAssetScene
              ? galleryAssetScene.isSaved !== undefined
                ? !galleryAssetScene.isSaved
                : false
              : activeEditingSubScene
              ? activeEditingSubScene.isSaved !== undefined
                ? !activeEditingSubScene.isSaved
                : false
              : activeEditingScene.isSaved !== undefined
              ? !activeEditingScene.isSaved
              : false
          }
          handleSave={this.handleSceneElementEditorSave}
          type={galleryAssetSceneType || sceneType}
          viewFeedbackAction={this.navigateToFeedback}
          openImageCropperFunc={this.openImageCropper}
          title={
            this.props.sceneType === "slideshow-slide" ||
            this.props.sceneType === "cover"
              ? `Edit ${
                  activeSceneFormData
                    ? activeSceneFormData.formType === "pdf"
                      ? "PDF"
                      : _capitalize(activeSceneFormData.formType)
                    : ""
                }`
              : `Edit ${
                  galleryAssetSceneFormData
                    ? galleryAssetSceneFormData.assetType === "pdf"
                      ? "PDF"
                      : _capitalize(galleryAssetSceneFormData.assetType)
                    : activeSceneFormData
                    ? activeSceneFormData.assetType === "pdf"
                      ? "PDF"
                      : _capitalize(activeSceneFormData.assetType)
                    : ""
                }`
          }
        />
        {this.needsAssetSelector() && this.state.assetSelectorOpen ? (
          <AssetSelector
            open={this.state.assetSelectorOpen}
            minWidthCaption={parseInt(styles.minWidthCaption, 10)}
            minCardMargin={parseInt(styles.minCardMargin, 10)}
            captionHeight={parseInt(styles.captionHeight, 10)}
            isModal
            standardBarButtonAction={this.handleAssetSelected}
            title="Select Asset"
            hideStandardButtonIfNonSelected
            allowsMultiple={this.state.assetSelectorAllowsMultiple}
            standardBarButtonLabel="SELECT"
            sidebarMode="none"
            isAssetSelectorMode
            closeModalAction={this.closeAssetSelector}
          />
        ) : null}
        <Chooser
          activeEditingScene={activeEditingScene}
          activeEditingSubScene={activeEditingSubScene}
          percCropData={percCropData}
          setActiveElementFunc={this.setActiveElement}
          storyID={storyID}
          sceneID={sceneID}
          elementID={elementID}
          scenes={scenes}
          slugBase={slugBase}
          type={sceneType}
          isSceneElementEditorOpen={isSceneElementEditorOpen}
          openLayoutSelectorFunc={() => this.openLayoutSelector(true)}
          openAssetSelectorFunc={this.openAssetSelector}
          openSceneEditorModalFunc={setActiveEditingSubscene}
          closeSceneElementEditorFunc={exitEditingSubscene}
          openImageCropperFunc={this.openImageCropper}
          ref="chooser"
          storyProperties={storyData}
        />
        {this.needsLayoutSelector() ? (
          <LayoutSelector
            open={this.state.layoutSelectorOpen}
            closeAction={this.closeLayoutSelector}
            isEditMode={this.state.layoutSelectorEditMode}
            slideId={activeEditingScene ? activeEditingScene._id : null}
            activeLayout={activeEditingScene ? activeEditingScene.layout : null}
            storyID={storyID}
            isModal
          />
        ) : null}
        <ImageCropper
          open={this.state.imageCropperOpen}
          closeAction={this.closeImageCropper}
          processCropAction={this.processCropAction}
          imageScr={imageCropperImageSrc}
          data={percCropData}
        />
      </div>
    );
  }

  setActiveElement(activeElement) {
    this.setState({ activeElement });
  }

  processCropAction(cropData) {
    const imageData = this.props.galleryAssetSceneFormData
      ? this.props.galleryAssetSceneFormData
      : this.props.sceneType === "collection"
      ? this.state.isSceneElementEditorOpen
        ? this.props.activeSceneFormData
        : this.state.imageCropperImageProps
      : this.props.activeSceneFormData
      ? this.props.activeSceneFormData
      : this.props.dataReadyForSave[this.state.imageCropperImageProps.param];
    const x = Math.round((cropData.x * imageData.width) / 100);
    const y = Math.round((cropData.y * imageData.height) / 100);
    const newWidth = Math.round((cropData.width * imageData.width) / 100);
    const newHeight = Math.round((cropData.height * imageData.height) / 100);

    let result = {};
    // More investigation will be required to figure out why cropping
    // a slideshow image requires alternative logic.
    if (this.props.sceneType === "slideshow-slide") {
      result = {
        ...imageData,
        use: this.state.activeElement,
        assetConfig: {
          ...imageData.assetConfig,
          cropCoordinates: {
            x,
            y,
            width: newWidth,
            height: newHeight
          }
        }
      };
    } else {
      result = {
        [imageData.param
          ? imageData.param
          : imageData.use
          ? imageData.use
          : "asset"]: {
          assetID: imageData.assetID,
          assetConfig: {
            cropCoordinates: {
              x,
              y,
              width: newWidth,
              height: newHeight
            }
          }
        }
      };
    }

    this.props.saveCropping(result);
  }

  /**
   *
   * @param {string or object} elementParam - If string, it's
   *    the param property on the image asset. Otherwise, it
   *    appears to be all the data on the image asset element.
   */
  openImageCropper(elementParam) {
    if (typeof elementParam === "string") {
      const imageProps = this.props.activeEditingScene.elements[elementParam];
      this.setState({
        imageCropperImageProps: {
          param: elementParam,
          assetID: imageProps.assetID,
          cloudPublicId: imageProps.cloudPublicId,
          cropCoordinates: imageProps.assetConfig
            ? imageProps.assetConfig.cropCoordinates
              ? imageProps.assetConfig.cropCoordinates
              : null
            : null,
          width: imageProps.width,
          height: imageProps.height
        },
        imageCropperOpen: true
      });
    } else {
      this.setState({
        imageCropperImageProps: {
          param: null,
          cropCoordinates: null,
          width: null,
          height: null
        },
        imageCropperOpen: true
      });
    }
  }

  closeImageCropper() {
    this.setState({ imageCropperOpen: false });
  }

  needsLayoutSelector() {
    return (
      this.props.sceneType === "slideshow-slide" ||
      this.props.sceneType === "cover"
    );
  }

  needsAssetSelector() {
    return (
      this.props.sceneType === "gallery" ||
      this.props.sceneType === "custom-game" ||
      this.props.sceneType === "colonykit-game" ||
      this.props.sceneType === "cargo-game" ||
      this.props.sceneType === "slideshow-slide" ||
      this.props.sceneType === "quiz-graded-questions" ||
      this.props.sceneType === "quiz-graded-results" ||
      this.props.sceneType === "timeline-events" ||
      this.props.sceneType === "collection" ||
      this.props.sceneType === "map-places" ||
      this.props.sceneType === "cover"
    );
  }

  componentDidMount() {
    // this.setState({editingForm: editingForm})
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.activeEditingScene !== "undefined") {
      if (
        !this.props.activeEditingScene ||
        this.props.activeEditingScene._id !== nextProps.activeEditingScene._id
      ) {
        this.props.createSceneRevertCopy(nextProps.activeEditingScene);
      }
    }
    if (typeof nextProps.activeEditingSubScene !== "undefined") {
      if (
        !this.props.activeEditingSubScene ||
        this.props.activeEditingSubScene._id !==
          nextProps.activeEditingSubScene._id
      ) {
        this.props.createSceneRevertCopy(nextProps.activeEditingSubScene);
      }
    }
    if (typeof nextProps.galleryAssetScene !== "undefined") {
      if (
        !this.props.galleryAssetScene ||
        this.props.galleryAssetScene._id !== nextProps.galleryAssetScene._id
      ) {
        this.props.createSceneRevertCopy(nextProps.galleryAssetScene);
      }
    }

    if (typeof nextProps.isSceneElementEditorOpen !== "undefined") {
      this.setState({
        isSceneElementEditorOpen: nextProps.isSceneElementEditorOpen
      });
    }

    if (
      nextProps.activeElement !== this.state.activeElement &&
      this.state.activeElement !== "background" &&
      nextProps.activeElement !== null
    ) {
      this.setState({ activeElement: nextProps.activeElement });
    }

    if (
      typeof nextProps.editingForm !== "undefined" ||
      nextProps.galleryAssetSceneEditingForm !== "undefined"
    ) {
      const editingForm =
        nextProps.galleryAssetSceneEditingForm || nextProps.editingForm;
      const deleteHandler = this.processAssetDelete;
      _map(editingForm, (value, key) => {
        if (key === "assetData") {
          editingForm[key].handleDeleteClick = deleteHandler;
        }
      });
      this.setState({ editingForm });
    }
  }

  openLayoutSelector(editMode) {
    if (editMode) {
      this.setState({ layoutSelectorEditMode: true });
    }
    this.setState({ layoutSelectorOpen: true });
  }

  closeLayoutSelector() {
    this.setState({ layoutSelectorOpen: false, layoutSelectorEditMode: false });
  }

  openAssetSelector(elementID, allowsMultiple, selectedTab) {
    const allowsMultipleState =
      typeof allowsMultiple !== "undefined"
        ? allowsMultiple
        : this.state.assetSelectorAllowsMultiple;
    // Should we use setState to store the selected tab if
    // it's passed?
    this.setState({
      assetSelectorOpen: true,
      activeElement: elementID,
      assetSelectorAllowsMultiple: allowsMultipleState,
      assetSelectorSelectedTab: selectedTab
    });
  }

  closeAssetSelector() {
    this.setState({
      assetSelectorOpen: false,
      activeElement:
        this.state.activeElement === "background" ? "background" : null
    });
  }

  handleSceneElementEditorSave() {
    // if it a subScene then we target a different scene ID

    // const editingSubScene =
    //   this.props.sceneType === "gallery" ||
    //   this.props.sceneType === "map-places" ||
    //   this.props.sceneType === "custom-game" ||
    //   this.props.sceneType === "colonykit-game" ||
    //   this.props.sceneType === "collection" ||
    //   this.props.sceneType === "cargo-game";

    // this.props.updateScene(editingSubScene);
    this.props.updateScene();
    this.closeSceneElementEditor(false);
  }

  closeSceneElementEditor(doRevert) {
    this.setState({ isSceneElementEditorOpen: false });
    if (doRevert) {
      this.props.revertScene();
    }
    this.props.setActiveEditingSubscene(null);
  }

  /*
  Not sure what value is, but it looks like a reference
  to the React asset element component.
  */
  processAssetDelete(value) {
    this.props.deleteAssetElement(value.use);
    this.closeSceneElementEditor(false);
  }

  handleAssetSelected(assetID, assetData) {
    /*
    If a reference to the mounted scene editor "subclass" exists,
    that means there's a method defined on that scene editor to 
    handle processing the asset selection.
    */
    if (this.refs.chooser.editor) {
      this.refs.chooser.editor.wrappedInstance.handleAssetSelected(
        assetID[0],
        this.state.activeElement
      );
    }

    this.closeAssetSelector();

    // Take additional action based on scene type.
    const sceneType = this.props.sceneType;
    if (typeof sceneType !== "undefined") {
      // Do nothing if the Timeline Event Editor or the Map Place Editor
      // is mounted. Both of those editors have handleAssetSelected() methods.
      //
      if (
        sceneType === "quiz-graded-questions" ||
        sceneType === "quiz-graded-results"
      ) {
        // Do nothing
      } else if (
        sceneType === "gallery" ||
        sceneType === "custom-game" ||
        sceneType === "collection" ||
        ((sceneType === "map-places" || sceneType === "timeline-events") &&
          (typeof this.state.activeElement === "undefined" ||
            this.state.activeElement === null))
      ) {
        // For galleries (and collection/map-place galleries), add a new sub-scene
        // when an asset is selected.
        const isRotationGallery =
          this.state.assetSelectorSelectedTab === "rotationGallery";
        this.props.createSubScene(assetID, isRotationGallery);
      } else if (sceneType === "slideshow-slide" || sceneType === "cover") {
        // For slideshow and cover scenes, the Asset Element Editor modal is
        // open. Update the asset element in the scene directly.
        this.props.setAssetElement(assetID, this.state.activeElement);
      } else if (typeof this.state.activeElement === "object") {
        // Not certain what this case covers yet.
        this.props.sceneSetAssetElement(
          this.state.activeElement.param,
          assetID,
          this.state.activeElement.section
        );
      } else {
        // Not sure which scene types leverage this catch-all.

        this.props.sceneSetAssetElement(this.state.activeElement, assetID);
      }
    }
  }

  navigateToFeedback() {
    this.props.navigateToFeedback();
  }
}

SceneEditor.defaultProps = {};

SceneEditor.propTypes = {
  goBackAction: PropTypes.string,
  activeEditingScene: PropTypes.object, // reference to full scene
  activeEditingSubScene: PropTypes.object, // reference to full scene
  activeSceneFormData: PropTypes.object, // reference to data that will populate form
  isSceneElementEditorOpen: PropTypes.bool,
  editingForm: PropTypes.object,
  storyID: PropTypes.string,
  languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sceneID: PropTypes.string,
  elementID: PropTypes.string,
  slugBase: PropTypes.string,
  sidebarMode: PropTypes.string,
  sceneType: PropTypes.string, // this is the type of the scene
  subsceneType: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return sceneEditorMapStateToProps(state, ownProps);
}

// TODO: fix the functions to hook up correct
function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    activeEditingScene,
    activeEditingSubScene,
    activeSceneFormData,
    collectionID,
    dataReadyForSave,
    editingForm,
    elementID,
    itemID,
    langID,
    localizedElements,
    sceneID,
    sceneType,
    slugBase,
    storyID,
    subsceneElement,
    subsceneID,
    subsceneType,
    galleryAssetSceneID,
    galleryAssetScene,
    galleryAssetDataReadyForSave,
    galleryAssetSceneType,
    galleryAssetSceneLocalizedElements,
    galleryAssetSceneElement,
    galleryAssetSceneEditingForm
  } = stateProps;

  return Object.assign({}, stateProps, {
    /**
     * Handle input change in the SceneElementEditor.
     * @property - Name of form field control that changed
     * @value - New form field control value
     */
    handleInputChange: (property, value) => {
      Logger.debug({ property, value }, "[SceneEditor] - handleInputChange");
      const newProperty =
        sceneType === "slideshow-slide"
          ? [elementID, property]
          : galleryAssetSceneID
          ? [galleryAssetSceneElement, property]
          : subsceneID && subsceneElement
          ? [subsceneElement, property]
          : property;

      const elementType = galleryAssetSceneElement
        ? galleryAssetScene.elements[galleryAssetSceneElement].type
        : activeEditingSubScene
        ? activeEditingSubScene.elements[subsceneElement].type
        : activeEditingScene.elements[elementID]
        ? activeEditingScene.elements[elementID].type
        : null;

      const isAssetMetaConfig = elementType === "asset";

      const updateSceneID = galleryAssetSceneID
        ? galleryAssetSceneID
        : subsceneID
        ? subsceneID
        : sceneID;

      const isValueString = typeof value === "string";

      /*
      Convert empty paragraph added by Quill to a true empty string.
      Sometimes the paragraph Quill adds has no classes and other
      times it does. If it has a class make sure there's only one
      paragraph that has JUST a paragraph break, because the regex
      will match an opening paragraph and all stuff in between up
      to the <br></p>.
      */
      const totalParagraphs = isValueString
        ? value.match(/<\/p>/gm)
          ? value.match(/<\/p>/gm).length
          : 0
        : 0;
      value = isValueString
        ? totalParagraphs === 1 &&
          (value.match(/<p><br><\/p>/gm) ||
            value.match(/^<p class="(.*?)"><br><\/p>$/gm))
          ? ""
          : value
        : value;

      const isLocalized = galleryAssetSceneLocalizedElements
        ? galleryAssetSceneLocalizedElements.includes(property)
        : localizedElements.includes(property);

      dispatchProps.setElementInput(
        newProperty,
        value,
        langID,
        updateSceneID,
        false,
        isAssetMetaConfig,
        isLocalized,
        galleryAssetSceneEditingForm || editingForm
      );
    },
    createSceneRevertCopy: sceneData =>
      dispatchProps.createSceneRevertCopy(sceneData),
    setAssetElement: (assetID, elementID, section) => {
      const selectedAssetID = assetID ? assetID[0] : null;
      const assetSceneID =
        sceneType === "quiz-graded-questions"
          ? activeEditingSubScene._id
          : sceneID;
      const sceneData =
        elementID === "background"
          ? {
              assetID: selectedAssetID,
              _id: sceneID,
              type: "asset",
              use: "background"
            }
          : {
              assetID: selectedAssetID,
              _id: assetSceneID,
              type: "asset"
            };
      dispatchProps.updateScene(
        assetSceneID,
        sceneData,
        editingForm,
        sceneType,
        subsceneType,
        elementID,
        section
      );
    },
    deleteAssetElement: elementID => {
      const currentSceneID = subsceneID || sceneID;
      dispatchProps.sceneDeleteAssetElement(currentSceneID, elementID);
    },
    updateScene: () => {
      const updateSceneID = galleryAssetSceneID
        ? galleryAssetSceneID
        : subsceneID
        ? subsceneID
        : sceneID;

      const newElementID = galleryAssetSceneElement
        ? galleryAssetSceneElement
        : subsceneElement
        ? subsceneElement
        : elementID;

      dispatchProps.updateScene(
        updateSceneID,
        galleryAssetDataReadyForSave || dataReadyForSave,
        galleryAssetSceneEditingForm || editingForm,
        sceneType,
        galleryAssetSceneType || subsceneType,
        newElementID
      );
    },
    saveCropping: data => {
      // const updateID = activeEditingSubScene
      //   ? activeEditingSubScene._id
      //   : activeEditingScene._id;

      const updateSceneID = galleryAssetSceneID
        ? galleryAssetSceneID
        : subsceneID
        ? subsceneID
        : sceneID;

      const newElementID = "asset";

      dispatchProps.updateScene(
        updateSceneID,
        data,
        galleryAssetSceneEditingForm || editingForm,
        sceneType,
        galleryAssetSceneType || subsceneType,
        newElementID
      );
    },
    createSubScene: (assetsData, isRotationGallery) => {
      const newSubsceneType =
        sceneType === "gallery" ||
        sceneType === "collection" ||
        sceneType === "map-places" ||
        sceneType === "timeline-events"
          ? "viewer-asset"
          : subsceneType;
      const updateSceneID =
        sceneType === "collection"
          ? itemID
          : sceneType === "map-places" || sceneType === "timeline-events"
          ? subsceneID
          : sceneID;
      const updateStoryID = sceneType === "collection" ? collectionID : storyID;
      dispatchProps.createSubScene(
        updateStoryID,
        updateSceneID,
        assetsData,
        sceneType,
        newSubsceneType,
        isRotationGallery
      );
    },
    setActiveEditingElement: elementID => {
      // NOTE: seems we are getting the use NOT the ID back from the slideshow viewer
      const url = `${slugBase}/${sceneID}/${elementID}`;
      ownProps.history.push(url);
      // dispatchProps.setActiveEditingElement(elementID)
    },
    navigateToFeedback: () => {
      const url = `/feedback/filterByScene/${subsceneID}`;
      ownProps.history.push(url);
    },
    sceneSetAssetElement: (elementID, assetID, section) => {
      const id = activeEditingSubScene
        ? activeEditingSubScene._id
        : activeEditingScene._id;
      dispatchProps.sceneSetAssetElement(id, elementID, assetID, section);
    },
    /**
     * Update the URL to trigger open/close of the Scene Element Editor.
     * If the subsceneID is null, the editor is being closed.
     *
     * Raymond: This logic is very messy. More thought needs to go into
     * generalizing this logic to eliminate the need to look at scene type.
     *
     * Bear in mind that when this function is called when a subscene gallery
     * image is clicked, galleryAssetSceneID will not yet be defined.
     */
    setActiveEditingSubscene: subsceneID => {
      let url;
      const isCollection = sceneType === "collection";
      const isSubSceneWithGallery =
        activeEditingSubScene && activeEditingSubScene.subScenes;
      const sceneUrlID = isCollection
        ? activeEditingScene._id
        : isSubSceneWithGallery
        ? activeEditingSubScene._id
        : sceneID;
      if (!subsceneID) {
        url = `${slugBase}${
          !galleryAssetSceneID &&
          sceneType !== "slideshow-slide" &&
          sceneType !== "cover" &&
          sceneType !== "collection"
            ? "/scenes"
            : ""
        }/${sceneUrlID}`;
      } else {
        if (isCollection) {
          url = `${slugBase}/${sceneUrlID}/subscenes/${subsceneID}`;
        } else if (isSubSceneWithGallery) {
          url = `${slugBase}/${sceneUrlID}/gallery/${subsceneID}`;
        } else {
          url = `${slugBase}/scenes/${sceneUrlID}/subscenes/${subsceneID}`;
        }
      }

      ownProps.history.push(url);
    },
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    exitEditingSubscene: () => {
      const url = `${slugBase}/scenes/${sceneID}`;
      ownProps.history.push(url);
    },
    revertScene: () => {
      dispatchProps.revertScene();
    }
  });
}
// storyActions,
export default connect(mapStateToProps, sceneActions, mergeProps)(SceneEditor);
